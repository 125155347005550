<template>
  <v-container fluid>
    <v-card flat>
      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent ref="form1">
            <v-row>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Personal Email"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    class="rounded-0"
                    label="Full name"
                    v-model="emergencyName"
                    :error-messages="errors"
                    hide-details="auto"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Office number"
                  :rules="{
                    required: true,
                    digits: 10,
                    regex: '^(0)\\d{9}$',
                  }"
                >
                  <v-text-field
                    v-model="emergencyTelephone"
                    class="rounded-0"
                    label="Telephone"
                    outlined
                    :error-messages="errors"
                    hide-details="auto"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Date of birth"
                  :rules="{
                    email: emergencyEmail ? 'true' : '',
                  }"
                >
                  <v-text-field
                    v-model="emergencyEmail"
                    class="rounded-0"
                    label="Email"
                    :error-messages="errors"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-footer inset color="transparent">
          <v-btn
            v-if="props.studentId"
            color="primary"
            @click="saveChanges"
            :disabled="!getters_abilities.includes('edit_student_access')"
          >
            save changes
          </v-btn>
          <v-btn
            :disabled="!getters_abilities.includes('create_student_access')"
            v-else
            type="submit"
            color="primary"
            @click="sendData"
          >
            Continue
          </v-btn>
        </v-footer>
      </v-card-actions>
    </v-card>

    <v-row>
      <v-col>
        <v-overlay absolute :value="overlay" opacity="1">
          <v-progress-circular
            indeterminate
            color="white"
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { defineComponent, getCurrentInstance, provide, reactive, toRefs, watch } from "vue";
  import Response from "@/components/ActionResponse/Response";
  import Api from "@/apis/Api";
  import { useActions, useGetters } from "vuex-composition-helpers";

  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, regex, digits, email } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("email", {
    ...email,
    message: "Email must be valid",
  });

  extend("regex", {
    ...regex,
    message: "{_field_}  not a valid name",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  export default defineComponent({
    components: { Response, ValidationProvider, ValidationObserver },
    props: ["studentId"],
    setup(props, context) {
      const vm = getCurrentInstance()
      const { signOut } = useActions(["signOut"]);

      const { getters_abilities } = useGetters(["getters_abilities"]);

      const emergency = reactive({
        emergencyName: "",
        emergencyTelephone: "",
        emergencyEmail: "",
        observer: null,
        error: false,
        actionResponse: false,
        overlay: false,
        msgHeader: null,
        msgBody: null,
        msgIcon: null,
        color: null,
      });

      const {
        observer,
        error,
        emergencyName,
        emergencyTelephone,
        emergencyEmail,
        msgHeader,
        msgBody,
        msgIcon,
        color,
        overlay,
        actionResponse,
      } = toRefs(emergency);

      const sendData = async () => {
        await observer.value.validate("form1").then((result) => {
          if (result) {
            let data = {
              emergency_name: emergencyName.value,
              emergency_mobile_number: emergencyTelephone.value,
              emergency_email: emergencyEmail.value,
            };

            context.emit("page", {
              bool: true,
              page: 3,
              data,
              name: "emergency",
            });
          }
          if (result === false) {
            context.emit("page", { bool: false, page: 3 });
            error.value = true;
            return;
          }
        });
      };

      watch(
        () => props?.studentId,
        (currStudent) => {
          emergencyName.value = currStudent.emergency_name;
          emergencyTelephone.value = currStudent.emergency_mobile_number;
          emergencyEmail.value = currStudent.emergency_email;
        }
      );

      const saveChanges = async () => {
        overlay.value = true;
        actionResponse.value = false;
        await observer.value.validate().then((result) => {
          if (result) {
            let studentBasket = {
              emergency_name: emergencyName.value,
              emergency_mobile_number: emergencyTelephone.value,
              emergency_email: emergencyEmail.value,
              id: props.studentId.id,
            };

            Api()
              .post("/student_emergency_information", { studentBasket })
              .then(() => {
                msgHeader.value = "Success";
                msgBody.value = " studentId Record Is Successfully Updated";
                msgIcon.value = "mdi-check-circle";
                color.value = "success";
              })

              .catch((e) => {
                emergencyName.value = props.studentId.emergency_name;
                emergencyTelephone.value =
                  props.studentId.emergency_mobile_number;
                emergencyEmail.value = props.studentId.emergency_email;

                overlay.value = false;
                actionResponse.value = true;
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
                color.value = "error";

                if (e.response.status === 423) {
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                } else {
                  msgBody.value =
                    "Try Again Later or Call the System Administrator";
                }
              })
              .finally(() => {
                actionResponse.value = true;
                overlay.value = false;
              });
          }
        });
      };

      provide("color", color);

      return {
        ...toRefs(emergency),
        sendData,
        saveChanges,
        props,
        getters_abilities,
      };
    },
  });
</script>
