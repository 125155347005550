var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form1",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Personal Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"outlined":"","label":"Full name","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.emergencyName),callback:function ($$v) {_vm.emergencyName=$$v},expression:"emergencyName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('validation-provider',{attrs:{"name":"Office number","rules":{
                  required: true,
                  digits: 10,
                  regex: '^(0)\\d{9}$',
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"label":"Telephone","outlined":"","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.emergencyTelephone),callback:function ($$v) {_vm.emergencyTelephone=$$v},expression:"emergencyTelephone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('validation-provider',{attrs:{"name":"Date of birth","rules":{
                  email: _vm.emergencyEmail ? 'true' : '',
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"label":"Email","error-messages":errors,"outlined":"","hide-details":"auto"},model:{value:(_vm.emergencyEmail),callback:function ($$v) {_vm.emergencyEmail=$$v},expression:"emergencyEmail"}})]}}])})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-footer',{attrs:{"inset":"","color":"transparent"}},[(_vm.props.studentId)?_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.getters_abilities.includes('edit_student_access')},on:{"click":_vm.saveChanges}},[_vm._v(" save changes ")]):_c('v-btn',{attrs:{"disabled":!_vm.getters_abilities.includes('create_student_access'),"type":"submit","color":"primary"},on:{"click":_vm.sendData}},[_vm._v(" Continue ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlay,"opacity":"1"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"64"}})],1)],1)],1),(_vm.actionResponse)?_c('Response',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.msgHeader))]},proxy:true},{key:"body",fn:function(){return [_vm._v(_vm._s(_vm.msgBody))]},proxy:true},{key:"icon",fn:function(){return [_vm._v(_vm._s(_vm.msgIcon))]},proxy:true}],null,false,916177833)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }