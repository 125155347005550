<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent ref="form1">
            <v-row justify="center" align="center">
              <v-col cols="12" lg="4">
                <v-hover v-slot="{ hover }" style="cursor: pointer !important">
                  <v-card
                    :elevation="hover ? 4 : 2"
                    :class="{ 'on-hover': hover, shake: invalidImage }"
                    @click="triggerUpload"
                    width="300"
                    height="300"
                    outlined
                  >
                    <input
                      class="d-none"
                      ref="uploader"
                      type="file"
                      accept="image/*"
                      @change="uploadImage"
                    />
                    <v-img
                      :aspect-ratio="1 / 1"
                      contain
                      :src="image"
                      :lazy-src="image"
                    >
                      <v-row
                        class="fill-height flex-column"
                        align="center"
                        justify="center"
                      >
                        <p
                          v-if="!image"
                          :class="{ 'show-btns': hover }"
                          class="title text-center px-4 text-wrap"
                        >
                          CLICK TO ADD PROFILE PICTURE
                        </p>
                      </v-row>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" lg="8">
                <v-row>
                  <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Title"
                      rules="required"
                    >
                      <v-select
                        :error-messages="errors"
                        :menu-props="{ down: true, offsetY: true }"
                        outlined
                        class="rounded-0"
                        label="Title"
                        v-model="title"
                        hide-details="auto"
                        :items="titles"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Titles...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Gender"
                      rules="required"
                    >
                      <v-select
                        :error-messages="errors"
                        :menu-props="{ down: true, offsetY: true }"
                        :items="genders"
                        class="rounded-0"
                        v-model="gender"
                        hide-details="auto"
                        outlined
                        label="Gender"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Loading
                              <strong>Genders...</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Other Names"
                      rules="required|alpha_spaces"
                    >
                      <v-text-field
                        :error-messages="errors"
                        outlined
                        class="rounded-0"
                        label="Other Names"
                        v-model="otherNames"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Names  "
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        outlined
                        class="rounded-0"
                        label="Last Name"
                        v-model="lastName"
                        hide-details="auto"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      ref="dobDialog"
                      v-model="menuDob"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Date of birth"
                          rules="required"
                        >
                          <v-text-field
                            :error-messages="errors"
                            v-model="dob"
                            class="rounded-0"
                            label="Date of Birth"
                            readonly
                            hide-details="auto"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker v-model="dob" @input="menuDob = false">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="NHIS"
                      :rules="{
                        regex: nhis ? 'required' : '',
                      }"
                    >
                      <v-text-field
                        outlined
                        :error-messages="errors"
                        class="rounded-0"
                        label="NHIS"
                        hide-details="auto"
                        v-model="nhis"
                      ></v-text-field>
                    </validation-provider>
                  </v-col> -->
                  <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Nationality"
                      rules="required"
                    >
                      <v-autocomplete
                        class="rounded-0"
                        :error-messages="errors"
                        :menu-props="{ down: true, offsetY: true }"
                        label="Nationality"
                        :items="nationalities"
                        item-text="nationality"
                        outlined
                        hide-details="auto"
                        v-model="nationality"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Not
                              <strong>Found</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile number"
                      :rules="{
                        required: true,
                        digits: 10,
                        regex: '^(0)\\d{9}$',
                      }"
                    >
                      <v-text-field
                        outlined
                        hide-details="auto"
                        class="rounded-0"
                        label="Mobile Number"
                        :error-messages="errors"
                        v-model="mobileNumber"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Personal Email"
                      rules="required|email"
                    >
                      <v-text-field
                        outlined
                        class="rounded-0"
                        label="Personal Email"
                        hide-details="auto"
                        :error-messages="errors"
                        v-model="email"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="ml-2">
        <v-footer color="transparent" inset>
          <v-btn
            class="font-weight-bold"
            v-if="props.studentId"
            color="primary"
            @click="saveChanges"
            :disabled="!getters_abilities.includes('edit_student_access')"
          >
            save changes
          </v-btn>
          <v-btn
            :disabled="!getters_abilities.includes('create_student_access')"
            v-else
            type="submit"
            color="primary"
            @click="sendData"
          >
            Continue
          </v-btn>
        </v-footer>
      </v-card-actions>
    </v-card>

    <div class="text-center" v-if="sheet">
      <v-bottom-sheet v-model="sheet" persistent>
        <v-sheet class="text-center" height="150px">
          <div class="py-3 body-1">
            Do you want to proceed without a <b>profile picture</b> ?
          </div>
          <v-btn text color="error" @click="sheet = !sheet"> close </v-btn>
          <v-btn
            class="font-weight-bold"
            color="success"
            type="button"
            @click="withoutProfilePix"
          >
            Continue
          </v-btn>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <v-row>
      <v-col>
        <v-overlay absolute :value="overlay" opacity="1">
          <v-progress-circular
            indeterminate
            color="white"
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { defineComponent, getCurrentInstance, provide, reactive, toRefs, watch } from "vue";
  import nationalities from "@/apis/nationalities";

  import Api from "@/apis/Api";
  import Response from "@/components/ActionResponse/Response";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
  import {
    required,
    numeric,
    alpha_spaces,
    regex,
    digits,
    email,
  } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("email", {
    ...email,
    message: "Email must be valid",
  });

  extend("numeric", {
    ...numeric,
    message: "{_field_} should be numbers only",
  });

  extend("regex", {
    ...regex,
    message: "{_field_}  not a valid",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} can only accepts alphabets",
  });

  export default defineComponent({
    props: ["reset", "studentId"],
    components: { Response, ValidationProvider, ValidationObserver },
    setup(props, context) {
      const vm = getCurrentInstance()
      const { signOut } = useActions(["signOut"]);
      const { getters_abilities } = useGetters(["getters_abilities"]);
      const personal = reactive({
        observer: null,
        menuDob: false,
        dob: "",
        title: "",
        gender: "",
        otherNames: "",
        lastName: "",
        // nhis: "",
        genders: ["Male", "Female", "Prefer Not To Say"],
        titles: ["Mr.", "Miss.", "Mrs.", "Dr."],
        email: "",
        nationality: "",
        nationalities: nationalities,
        mobileNumber: "",
        uploader: null,
        image: null,
        error: false,
        sheet: false,
        invalidImage: false,
        profilePicture: null,
        msgHeader: null,
        msgBody: null,
        msgIcon: null,
        color: null,
        overlay: false,
        actionResponse: false,
      });

      const {
        invalidImage,
        dob,
        title,
        gender,
        otherNames,
        lastName,
        email,
        nationality,
        mobileNumber,
        image,
        uploader,
        observer,
        sheet,
        error,
        // nhis,
        profilePicture,
        msgHeader,
        msgBody,
        msgIcon,
        color,
        overlay,
        actionResponse,
        titles,
      } = toRefs(personal);

      const triggerUpload = () => uploader.value.click();

      watch(
        () => props?.studentId,
        (user) => {
          title.value = user.title;
          otherNames.value = user.other_name;
          lastName.value = user.last_name;
          dob.value = user.dob;
          image.value = user.avatar_url;
          // nhis.value = user.nhis;
          nationality.value = user.nationality;
          mobileNumber.value = user.mobile_number;
          email.value = user.email;
        }
      );

      watch(
        () => title.value,
        (title) => {
          switch (title) {
            case "Miss.":
            case "Mrs.":
              gender.value = "Female";
              break;
            case "Mr.":
              gender.value = "Male";
              break;

            default:
              gender.value = null;
              break;
          }
        }
      );

      watch(
        () => gender.value,
        (gender) => {
          switch (gender) {
            case "Male":
              titles.value = ["Mr.", "Mrs.", "Dr."];
              break;
            case "Female":
              titles.value = ["Miss.", "Mrs.", "Dr."];
              break;
          }
        }
      );

      const uploadImage = (e) => {
        invalidImage.value = false;
        profilePicture.value = e.target.files[0];
        if (!profilePicture.value.type.match("image/*")) {
          image.value = null;
          return setTimeout(() => (invalidImage.value = true), 100);
        }
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          image.value = e.target.result;
        };
      };

      const sendData = async () => {
        await observer.value.validate("form1").then((result) => {
          if (result) {
            let data = {
              title: title.value,
              gender: gender.value,
              other_name: otherNames.value,
              lastname: lastName.value,
              // nhis: nhis.value,
              dob: dob.value,
              nationality: nationality.value,
              mobile_number: mobileNumber.value,
              email: email.value,
              image: profilePicture.value,
            };

            if (image.value === null) {
              sheet.value = true;
              return;
            }
            context.emit("page", { bool: true, page: 1, data, name: "personal" });
          }
          if (result === false) {
            context.emit("page", { bool: false, page: 1 });
            error.value = true;
            return;
          }
        });
      };

      const withoutProfilePix = async () => {
        await observer.value.validate("form1").then((result) => {
          if (result === true) {
            let data = {
              title: title.value,
              gender: gender.value,
              other_name: otherNames.value,
              lastname: lastName.value,
              // nhis: nhis.value,
              dob: dob.value,
              image: profilePicture.value,
              nationality: nationality.value,
              mobile_number: mobileNumber.value,
              email: email.value,
            };
            context.emit("page", { bool: true, page: 1, data, name: "personal" });
            sheet.value = false;
          } else {
            context.emit("page", { bool: false, page: 1 });
            error.value = true;
          }
        });
      };

      const saveChanges = async () => {
        overlay.value = true;
        actionResponse.value = false;
        await observer.value.validate().then((result) => {
          if (result) {
            let data = {
              title: title.value,
              gender: gender.value,
              other_name: otherNames.value,
              last_name: lastName.value,
              // nhis: nhis.value,
              dob: dob.value,
              nationality: nationality.value,
              mobile_number: mobileNumber.value,
              email: email.value,
              id: props.studentId.id,
            };

            Api()
              .post("/student_general", data)
              .then(() => {
                msgHeader.value = "Success";
                msgBody.value = " User Record Is Successfully Updated";
                msgIcon.value = "mdi-check-circle";
                color.value = "success";
              })

              .catch((e) => {
                overlay.value = false;
                actionResponse.value = true;
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
                color.value = "error";

                if (e.response.status === 423) {
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                } else {
                  msgBody.value =
                    "Try Again Later or Call the System Administrator";
                }

                title.value = props.studentId.title;
                otherNames.value = props.studentId.other_name;
                lastName.value = props.studentId.last_name;
                dob.value = props.studentId.dob;
                image.value = props.studentId.avatar_url;
                // nhis.value = props.studentId.nhis;
                nationality.value = props.studentId.nationality;
                mobileNumber.value = props.studentId.mobile_number;
                email.value = props.studentId.email;
              })
              .finally(() => {
                actionResponse.value = true;
                overlay.value = false;
              });
          }
        });
      };

      provide("color", color);

      return {
        ...toRefs(personal),
        sendData,
        triggerUpload,
        uploadImage,
        withoutProfilePix,
        props,
        saveChanges,
        getters_abilities,
      };
    },
  });
</script>
<style scoped>
  .shake {
    border: 1px solid red;
    border-radius: 5px;
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
