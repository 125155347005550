<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent ref="form1">
            <v-row>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Program"
                  rules="required"
                >
                  <v-autocomplete
                    :loading="loadProgram"
                    :error-messages="errors"
                    :disabled="props.studentId ? true : false"
                    v-model="program"
                    :items="itemsProgram"
                    @change="selectedProgram"
                    @keyup="searchProgram"
                    hide-selected
                    item-text="program_name_certificate"
                    hide-details="auto"
                    item-value="id"
                    label="Search Program"
                    class="rounded-0"
                    outlined
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title v-if="loadProgram">
                          <span class="mr-4">
                            <v-progress-circular
                              color="primary"
                              indeterminate
                            ></v-progress-circular>
                          </span>
                          Searching
                          <strong>Program...</strong>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          No
                          <strong>Program Found...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Department"
                  rules="required"
                >
                  <v-select
                    :error-messages="errors"
                    :menu-props="{ down: true, offsetY: true }"
                    v-model="organogram_id"
                    :items="itemsDepartment"
                    hide-selected
                    :disabled="props.studentId ? true : false"
                    item-text="department_name"
                    item-value="id"
                    label="Department"
                    hide-details="auto"
                    class="rounded-0"
                    outlined
                  >
                    <template #item="{ item }">
                      <span>
                        <b>
                          {{ `${item.office_type} of ` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                    </template>
                    <template #selection="{ item }">
                      <span>
                        <b class="grey--text">
                          {{ `${item.office_type} of ` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Academic Calendar"
                  rules="required"
                >
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    @click="fetchCalendar"
                    label="Academic Calendar"
                    v-model="calendar_id"
                    :items="basket_calendar"
                    :loading="iscalendarLoaded"
                    item-text="academic_calendar"
                    class="rounded-0"
                    hide-details="auto"
                    item-value="id"
                    outlined
                    :error-messages="errors"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Current Academic Calendar...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Intake"
                  rules="required"
                >
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    outlined
                    label="Intake"
                    v-model="intake"
                    @click="fetchIntake"
                    :loading="isintakeLoaded"
                    hide-details="auto"
                    :items="basket_intake"
                    item-text="intake"
                    class="rounded-0"
                    item-value="id"
                    :error-messages="errors"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Intakes...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Level"
                  rules="required"
                >
                  <v-select
                    v-model="level"
                    :items="basket_level"
                    :loading="loadLevel"
                    item-value="id"
                    item-text="level"
                    outlined
                    class="rounded-0"
                    hide-details="auto"
                    label="Level"
                    :error-messages="errors"
                    :menu-props="{ down: true, offsetY: true }"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search
                          <strong>Levels...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Session"
                  rules="required"
                >
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :items="basket_session"
                    item-text="session"
                    item-value="id"
                    @click="fetchSession"
                    :loading="issessionLoaded"
                    hide-details="auto"
                    outlined
                    class="rounded-0"
                    label="Session"
                    v-model="session"
                    :error-messages="errors"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Sessions...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row justify="start">
              <v-col cols="12" lg="3">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  ref="admissionDialog"
                  v-model="modaladmissionDate"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Admission Date"
                      rules="required"
                    >
                      <v-text-field
                        v-model="admissionDate"
                        label="Admission Date"
                        hide-details="auto"
                        readonly
                        :disabled="!intake && !calendar_id"
                        outlined
                        v-bind="attrs"
                        class="rounded-0"
                        v-on="on"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    landscape
                    type="month"
                    v-model="admissionDate"
                    scrollable
                    :allowed-dates="allowedDates"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modaladmissionDate = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.admissionDialog.save(admissionDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Student Type"
                  rules="required"
                >
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    label="Student Type"
                    v-model="selectedstudentType"
                    :items="studentTypes"
                    outlined
                    class="rounded-0"
                    hide-details="auto"
                    :error-messages="errors"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Student Type(s)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Campus"
                  rules="required"
                >
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    v-model="campus"
                    item-value="id"
                    item-text="campus_name"
                    @click="fetchCampus"
                    :items="basket_campus"
                    :loading="iscampusLoaded"
                    class="rounded-0"
                    hide-details="auto"
                    label="Campus"
                    outlined
                    :error-messages="errors"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Campus(es)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>

              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Grading System"
                  rules="required"
                >
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    label="Grading System"
                    v-model="grade"
                    :items="itemsGrades"
                    item-text="name"
                    class="rounded-0"
                    hide-details="auto"
                    item-value="id"
                    outlined
                    :error-messages="errors"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Grading Systems...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <!--  <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Hall"
                  :rules="{
                    regex: hall ? 'required' : '',
                  }"
                >
                  <v-text-field
                    outlined
                    label="Hall"
                    v-model="hall"
                    hide-details="auto"
                    class="rounded-0"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col> -->
              <v-col cols="12" lg="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Student Student"
                  rules="required"
                >
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="loadStatus"
                    v-model="status"
                    :items="itemsStatus"
                    class="rounded-0"
                    hide-selected
                    hide-details="auto"
                    item-text="status"
                    item-value="id"
                    label="Student Status"
                    outlined
                    :error-messages="errors"
                    @click="fetchStudentStatus"
                  >
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="font-weight-bold"
          bottom
          v-if="props.studentId"
          color="primary"
          @click="saveChanges"
        >
          save changes
        </v-btn>
        <v-btn
          :disabled="!getters_abilities.includes('create_student_access')"
          bottom
          v-else
          type="submit"
          color="primary"
          @click="sendData"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-row>
      <v-col>
        <v-overlay absolute :value="overlay" opacity="1">
          <v-progress-circular
            indeterminate
            color="white"
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { defineComponent, watch, reactive, toRefs, provide, getCurrentInstance } from "vue";
  import debounce from "@/debounce/debounce";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import moment from "moment";
  import Response from "@/components/ActionResponse/Response";
  import Api from "@/apis/Api";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { required } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} cannot be empty",
  });
  export default defineComponent({
    props: ["studentId"],
    components: { Response, ValidationObserver, ValidationProvider },
    setup(props, context) {
      const vm = getCurrentInstance()
      const {
        getProgram,
        getLevel,
        getIntake,
        getCampus,
        getSession,
        getCurCalendar,
        getStudentStatus,
        signOut,
      } = useActions([
        "getProgram",
        "getLevel",
        "getIntake",
        "getSession",
        "getCampus",
        "getCampus",
        "getSession",
        "getCurCalendar",
        "getStudentStatus",
        "signOut",
      ]);

      const {
        getters_program,
        getters_level,
        getters_intake,
        getters_campus,
        getters_session,
        getters_curcalendar,
        getters_student_status,

        getters_abilities,
        user,
      } = useGetters([
        "getters_program",
        "getters_level",
        "getters_intake",
        "getters_campus",
        "getters_session",
        "getters_curcalendar",
        "getters_student_status",

        "getters_saved_student",
        "getters_abilities",
        "user",
      ]);

      const school = reactive({
        grade: null,
        basket_calendar: [],
        calendar_id: null,
        menuDob: false,
        modaladmissionDate: false,
        modalcompletionDate: false,
        completionDialog: false,
        studentTypes: ["Regular", "Matured"],
        disabledDepartment: true,
        organogram_id: null,
        itemsDepartment: [],
        admissionDate: "",
        completionDate: "",
        selectedstudentType: "",
        intake: "",
        session: "",
        program: "",
        campus: "",
        // hall: "",
        level: "",
        loadProgram: false,
        disabledLevel: true,
        loadStatus: false,
        itemsStatus: [],
        itemsProgram: [],
        itemsGrades: [],
        iscalendarLoaded: false,
        status: "",
        basket_semester: [],
        basket_level: [],
        basket_intake: [],
        basket_campus: [],
        basket_session: [],
        isintakeLoaded: false,
        issessionLoaded: false,
        iscampusLoaded: false,
        loadLevel: false,
        getprogInfo: null,
        uploader: null,
        image: null,
        duration: null,
        observer: null,
        error: false,
        overlay: false,
        actionResponse: false,
        msgHeader: null,
        msgBody: null,
        msgIcon: null,
        color: null,
      });

      const {
        msgHeader,
        msgBody,
        msgIcon,
        color,
        overlay,
        actionResponse,
        observer,
        duration,
        organogram_id,
        itemsDepartment,
        calendar_id,
        loadProgram,
        itemsProgram,
        isintakeLoaded,
        issessionLoaded,
        loadLevel,
        iscampusLoaded,
        basket_level,
        basket_campus,
        basket_session,
        basket_intake,
        itemsStatus,
        admissionDate,
        completionDate,
        selectedstudentType,
        intake,
        session,
        program,
        campus,
        // hall,
        level,
        iscalendarLoaded,
        basket_calendar,
        loadStatus,
        status,
        getprogInfo,
        itemsGrades,
        grade,
        error,
      } = toRefs(school);

      const searchProgram = debounce(() => {
        loadProgram.value = true;
        getProgram()
          .then(() => {
            itemsProgram.value = getters_program.value;
          })
          .then(() => {
            loadProgram.value = false;
          });
      });

      const selectedProgram = (i) => {
        if (i) {
          getprogInfo.value = getters_program.value.find((item) => item.id === i);
          itemsDepartment.value = [getprogInfo.value.organogram];
          itemsGrades.value = getprogInfo.value.grades;
          duration.value = getprogInfo.value.duration;

          loadLevel.value = true;
          getLevel().then(() => {
            loadLevel.value = false;
            /* basket_level.value = !getprogInfo.value.program_state
                                                                ? getters_level.value.filter((level) => level.level <= 200)
                                                                : getters_level.value.filter((level) => level.level === 300); */

            if (!getprogInfo.value.program_state) {
              basket_level.value =
                getprogInfo.value.certificate.certificate_id == 4
                  ? getters_level.value.filter((level) => level.level <= 200)
                  : getprogInfo.value.certificate.certificate_id == 3
                  ? getters_level.value.filter((level) => level.level < 200)
                  : getters_level.value;
            } else
              basket_level.value = getters_level.value.filter(
                (level) => level.level === 300
              );
          });
        }
      };

      const fetchIntake = () => {
        isintakeLoaded.value = true;
        if (getters_intake.value.length > 0) {
          basket_intake.value = getters_intake.value.filter(
            (intake) => intake.current === true
          );
          isintakeLoaded.value = false;
          return;
        }

        getIntake().then(() => {
          basket_intake.value = getters_intake.value.filter(
            (intake) => intake.current
          );
          isintakeLoaded.value = false;
        });
      };

      watch(
        () => admissionDate.value,
        () => {
          if (admissionDate.value) {
            const date = new Date(admissionDate.value);
            const newDate = new Date(
              date.setFullYear(date.getFullYear() + duration.value)
            );

            completionDate.value = moment(newDate).format("YYYY-MM");
          }
        }
      );

      const fetchCampus = () => {
        iscampusLoaded.value = true;
        if (getters_campus.value.length > 0) {
          iscampusLoaded.value = false;
          basket_campus.value = [...basket_campus.value];
        }

        getCampus().then(() => {
          if (getters_abilities.value.includes("all_campus_access")) {
            basket_campus.value = getters_campus.value;
          } else {
            basket_campus.value = getters_campus.value.filter(
              (campus) => campus.id === parseInt(user.value.campus[0].id)
            );
          }
          iscampusLoaded.value = false;
        });
      };

      const fetchStudentStatus = () => {
        loadStatus.value = true;

        getStudentStatus().then(() => {
          itemsStatus.value = getters_student_status.value.filter(
            (status) => status.status.toLowerCase() === "in school"
          );
          loadStatus.value = false;
        });
      };

      const fetchSession = () => {
        issessionLoaded.value = true;
        if (getters_session.value.length > 0) {
          basket_session.value = getters_session.value;
          issessionLoaded.value = false;
          return;
        }

        getSession().then(() => {
          basket_session.value = getters_session.value;
          issessionLoaded.value = false;
        });
      };

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_curcalendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_curcalendar.value;
          return;
        }
        getCurCalendar().then(() => {
          basket_calendar.value = getters_curcalendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const sendData = async () => {
        await observer.value.validate("form1").then((result) => {
          if (result) {
            let data = {
              organogram_id: organogram_id.value,
              program_id: program.value,
              grade_id: grade.value,
              intake_id: intake.value,
              level_id: level.value,
              session_id: session.value,
              campus_id: campus.value,
              admission_date: admissionDate.value,
              completion_date: completionDate.value,
              student_type: selectedstudentType.value,
              // hall: hall.value,
              calendar_id: calendar_id.value,
              staff_id: user.value.id,
              student_status_id: status.value,
            };
            context.emit("page", {
              bool: true,
              page: 2,
              data,
              name: "school",
            });
          }
          if (result === false) {
            context.emit("page", { bool: false, page: 2 });
            error.value = true;
            return;
          }
        });
      };

      watch(
        () => props?.studentId,
        (curr) => {
          console.log(curr);
          Promise.all([
            getProgram(),
            fetchCalendar(),
            fetchSession(),
            fetchIntake(),
            fetchCampus(),
            fetchStudentStatus(),
          ]).then(() => {
            searchProgram();
            selectedProgram(curr.program_id);
            calendar_id.value = curr.calendar_id;
            intake.value = curr.intake_id;
            session.value = curr.session_id;
            program.value = curr.program_id;
            organogram_id.value = curr.organogram_id;
            level.value = curr.level_id;
            campus.value = curr.campus_id;
            // hall.value = curr.hall;
            admissionDate.value = curr.admission_date;
            selectedstudentType.value = curr.student_type;
            grade.value = curr.grade_id;
            status.value = curr.student_status_id;
          });
        }
      );

      const saveChanges = async () => {
        observer.value.validate().then((result) => {
          if (!result) return;
        });

        overlay.value = true;
        actionResponse.value = false;
        let studentBasket = {
          intake_id: intake.value,
          session_id: session.value,
          campus_id: campus.value,
          level_id: level.value,
          student_type: selectedstudentType.value,
          student_status_id: status.value,
          admission_date: admissionDate.value,
          completion_date: completionDate.value,
          id: props.studentId.id,
        };

        Api()
          .post("/student_school_information", { studentBasket })
          .then(() => {
            msgHeader.value = "Success";
            msgBody.value = " studentId Record Is Successfully Updated";
            msgIcon.value = "mdi-check-circle";
            color.value = "success";
          })
          .catch((e) => {
            overlay.value = false;
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              case 403:
                msgBody.value = e.response.data.message;
                break;

              default:
                msgBody.value =
                  "Try Again Later or Call the System Administrator";
                break;
            }

            calendar_id.value = props?.studentId.calendar_id;
            intake.value = props?.studentId.intake_id;
            session.value = props?.studentId.session_id;
            program.value = props?.studentId.program_id;
            organogram_id.value = props?.studentId.organogram_id;
            level.value = props?.studentId.level_id;
            campus.value = props?.studentId.campus_id;
            // hall.value = props?.studentId.hall;
            admissionDate.value = props?.studentId.admission_date;
            selectedstudentType.value = props?.studentId.student_type;
            grade.value = props?.studentId.grade_id;
            status.value = props?.studentId.student_status_id;
          })
          .finally(() => {
            actionResponse.value = true;
            overlay.value = false;
          });
      };

      const allowedDates = (val) => {
        let currentIntake = basket_intake.value.find(
          ({ id }) => id === intake.value
        );
        return val === moment().format("YYYY") + "-" + currentIntake.intake_code;
      };

      provide("color", color);

      return {
        ...toRefs(school),
        searchProgram,
        selectedProgram,
        fetchIntake,
        fetchCampus,
        fetchStudentStatus,
        fetchSession,
        fetchCalendar,
        sendData,
        props,
        saveChanges,
        getters_abilities,
        allowedDates,
      };
    },
  });
</script>
