<template>
  <v-container fluid>
    <v-card
      v-if="!props.studentId"
      @drop.prevent="sendFile"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      min-height="200"
      class="pa-2"
      :class="{
        'grey lighten-0': dragover,
        'white--text': dragover,
        shake: emptyImg,
      }"
      elevation="0"
      color="grey lighten-1"
      :style="{ outline: dragover ? '4px dashed white' : '4px dashed #424242' }"
      style="cursor: pointer; outline-offset: -10px"
    >
      <input
        style="
          opacity: 0;
          width: 100%;
          position: absolute;
          height: 200px;
          cursor: pointer;
        "
        ref="uploader"
        type="file"
        accept="image/*"
        @change="sendFileInput"
        multiple
      />
      <div class="text-center headline" style="padding: 70px 0">
        Drag and drop, or Click to Upload files
        <div class="text-center">
          <v-icon x-large :class="{ 'white--text': dragover }">
            mdi-cloud-upload
          </v-icon>
        </div>
      </div>
    </v-card>

    <p class="body-1">
      Acceptable File Types:
      <span class="font-weight-bold"> .pdf, .jpeg, .jpg and .png only</span>
    </p>

    <v-row justify="center" v-if="uploadedFiles.length > 0">
      <v-col
        cols="12"
        md="2"
        lg="2"
        v-for="(preview, index) in uploadedFiles"
        :key="index"
      >
        <v-sheet
          auto-height
          class="v-sheet--offset mx-auto"
          color="white"
          max-width="calc(100% - 30px)"
        >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 10 : 2" :class="{ 'on-hover': hover }">
              <v-img class="" :src="preview.pix" :aspect-ratio="16 / 9">
                <v-expand-transition :key="index">
                  <div class="d-flex justify-space-between">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="removeImage(preview.name)"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          :class="{ 'show-btns': hover }"
                          :color="transparent"
                          class="float-right"
                        >
                          <v-icon
                            :color="transparent"
                            :class="{ 'show-btns': hover }"
                          >
                            mdi-close-circle
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Click to delete</span>
                    </v-tooltip>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </v-sheet>
      </v-col>
    </v-row>

    <v-expand-transition tag="v-list">
      <div v-if="getters_document.length > 0">
        <v-row class="py-4">
          <v-col lg="2" md="2" v-for="(item, i) in img" :key="i">
            <v-sheet
              auto-height
              class="v-sheet--offset mx-auto"
              color="white"
              max-width="calc(100% - 30px)"
            >
              <a :href="item" target="_blank" :download="`${item}-${i}`">
                <v-img :src="item" :lazy-src="item" />
              </a>
            </v-sheet>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-4">
          <v-col lg="2" md="2" v-for="(item, key) in pdf" :key="key">
            <p class="font-weight-bold body-1">PDF Files Here</p>
            <v-sheet
              auto-height
              class="v-sheet--offset mx-auto"
              color="white"
              max-width="calc(100% - 30px)"
            >
              <v-btn icon x-large>
                <a
                  style="text-decoration: none"
                  :href="item"
                  target="_blank"
                  :download="`${item}-${key}`"
                >
                  <v-icon x-large color="red darken-2">mdi-file-pdf-box</v-icon>
                </a>
              </v-btn>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>

    <v-row class="mt-3">
      <v-col>
        <v-btn v-if="!props.studentId" color="primary" @click="validate">
          Continue
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { defineComponent, reactive, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  export default defineComponent({
    props: ["studentId"],
    setup(props, context) {
      const { getDocument } = useActions(["getDocument"]);
      const { getters_document } = useGetters(["getters_document"]);

      const documentData = reactive({
        uploading: false,
        files: [],
        uploader: null,
        uploadedFiles: [],
        emptyImg: false,
        dragover: false,
        transparent: "rgba(255, 255, 255, 0)",
        pdf: [],
        img: [],
        validType: ["image/jpg", "image/jpeg", "image/png", "application/pdf"],
      });

      const {
        files,
        uploadedFiles,
        emptyImg,
        dragover,
        pdf,
        img,
        validType,
        uploader,
      } = toRefs(documentData);

      const sendFile = (e) => {
        try {
          files.value = e.dataTransfer.files;
          const formData = new FormData();

          for (let i of files.value) {
            formData.append("file", i);
            let fileType = i.type;
            let condition = validType.value.indexOf(fileType) === -1;

            if (condition) {
              alert("error");
              return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(i);
            reader.onload = (e) => {
              uploadedFiles.value.push({
                name: i.name,
                size: i.size,
                pix: e.target.result,
              });
            };
            emptyImg.value = false;
          }
        } catch (error) {
          console.log(error);
        }
      };

      const sendFileInput = (e) => {
        try {
          files.value = e.target.files;

          for (let i of files.value) {
            const reader = new FileReader();
            reader.readAsDataURL(i);
            reader.onload = (e) => {
              uploadedFiles.value.push({
                name: i.name,
                size: i.size,
                pix: e.target.result,
              });
            };
            emptyImg.value = false;
          }
          uploader.value.value = null;
          dragover.value = true;
        } catch (error) {
          console.log(error);
        }
      };

      const removeImage = (img) => {
        const index = uploadedFiles.value.findIndex((item) => item.name === img);
        if (index > -1) uploadedFiles.value.splice(index, 1);
        if (uploadedFiles.value.length === 0) dragover.value = false;
      };

      const validate = () => {
        emptyImg.value = false;
        if (uploadedFiles.value.length > 0) {
          let data = { files: files.value };
          emptyImg.value = false;
          context.emit("page", { bool: true, page: 4, data, name: "files" });
        } else {
          context.emit("page", { bool: false, page: 4 });
          setTimeout(() => (emptyImg.value = true), 100);
        }
      };

      watch(
        () => props?.studentId,
        (studentId) => {
          getDocument(studentId?.id)
            .then(() => {
              pdf.value = [];
              img.value = [];
              getters_document.value.forEach((file) => {
                var fileExtension = file.split(".").pop();
                if (fileExtension == "pdf") {
                  pdf.value.push(file);
                } else {
                  img.value.push(file);
                }
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );

      return {
        sendFile,
        sendFileInput,
        removeImage,
        validate,
        getters_document,
        props,
        ...toRefs(documentData),
        /* resetFileInput, */
      };
    },
  });
</script>
<style scoped>
  .v-card {
    transition: opacity 0.4s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.6;
  }

  .show-btns {
    color: #002e69 !important;
  }

  .repeating-gradient {
    background-image: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 1) 5px,
      rgba(206, 206, 206, 0.25) 20px
    );
  }
</style>
<style scoped>
  .shake {
    outline: 4px dashed red !important;
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  .imageBorderEmpty {
    outline: 4px dashed white;
  }
  .imageBorderNotEmpty {
    outline: 4px dashed #424242;
  }
  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
